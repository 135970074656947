@use "src/styles/theme";
@use "src/styles/breakpoints";

.outerContainer {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block-end: calc(5 * var(--spacing-mega));
  padding-block-start: var(--spacing-mega);
  padding-inline: var(--spacing-large);
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  @include breakpoints.desktop {
    width: calc(10 * var(--spacing-mega));
  }
}

.cta {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  padding-block: var(--spacing-large);
  text-align: center;
}

.title {
  font-size: var(--font-size-jumbo);
  font-weight: var(--font-weight-bold);
  margin-block-end: var(--spacing-large);
  text-align: center;
}

.loginButton {
  margin-block-start: var(--spacing-small);
  font-weight: var(--font-weight-semibold);
  display: flex;
}

.googleButton {
  --themed-bg: var(--color-primary-faded);
  --themed-fg: var(--shade-9);
  @include theme.sepia {
    --themed-border: transparent;
  }

  @media (hover: hover) {
    &:hover {
      --themed-bg: var(--color-text-default);
      --themed-border: var(--color-text-default);
    }
  }
}

.facebookButton {
  --facebook-brand-color: #4267b2;

  --themed-bg: var(--facebook-brand-color);
  --themed-fg: var(--shade-0);
  --themed-border: var(--facebook-brand-color);
  @media (hover: hover) {
    &:hover {
      --themed-bg: var(--color-text-default);
      --themed-border: var(--color-text-default);
    }
  }
}

.emailSentContainer {
  text-align: center;
}
.paragraphContainer {
  margin-block-end: var(--spacing-medium);
}
.emailContainer {
  font-weight: var(--font-weight-bold);
}

.verificationCode {
  padding: var(--spacing-medium);
  background-color: var(--color-background-alternative-faint);
  border: 1px solid var(--color-secondary-faded);
  text-align: center;
  border-radius: var(--border-radius-rounded);
}

.resendEmailSection {
  margin-block-start: var(--spacing-medium);
  padding-block-start: var(--spacing-medium);
  border-block-start: 1px solid var(--color-background-elevated);
  display: flex;
  flex-direction: column;
}

.resendButton {
  margin-block-start: var(--spacing-small);
}

.privacyText {
  margin-block-start: var(--spacing-medium);
  font-size: var(--font-size-xsmall);
  color: var(--color-text-faded);
}
